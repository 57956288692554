import React from 'react';

import { Outlet, Link, useNavigate } from 'react-router-dom';

const LoginLayout = () => {
  return (
    <div className='h-screen flex flex-col '>
      <Outlet />
    </div>
  );
};

export default LoginLayout;
